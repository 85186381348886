<template>
    <div v-if="initailized">
        <section class="user-content" v-show="isDisplayResult">
            <h3>{{ displayEventName }}</h3>
            <ul>
                <li>
                    <p>會員ID</p>
                    <p class="show">{{ member.id }}</p>
                </li>
                <li v-if="showPhone">
                    <p>會員電話</p>
                    <p class="show">{{ member.phone }}</p>
                </li>
                <!-- <li>
                    <p>會員姓名</p>
                    <p class="show">{{ member.name }}</p>
                </li> -->
            </ul>
            <input v-if="this.hasConfirmBtn" ref="checkBtnRef" class="btn" type="submit" value="確認蓋章"
                @click="checkStamp()" />
            <input v-if="this.isScanConfirmed" ref="finishBtnRef" class="btn" type="submit" value="關閉闖關成功畫面"
                @click="finish()" style="margin-top: 10px; background-color: #5e98d9;" />
            <p class="done" v-show="stampDone">闖關<br />完成</p>
            <p class="error" v-show="stampError">!! 查無用戶</p>
        </section>
        <!-- <br />
            <br />
            <br />
            <br />
            <div style="text-align: center">
                <button @click="chooseEvent()">重新選擇關卡</button>
            </div> -->
    </div>
</template>

<script>
import axios from "axios";
import { routers } from "../data";


const restartAfterScanFinishMillis = 5000
const regex = /\/([^/]+)\/([^/]+)\//;

export default {
    name: "ScanResult",
    props: {
        events: {
            type: Array,
        },
        isDisplayResult: {
            type: Boolean,
            default: false
        },
        member: {
            type: Object,
        },
        hasConfirmBtn: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            initailized: false,
            stampResult: {
                status: ""
            },
            isScanConfirmed: false,
            path: this.$route.path.match(regex)[2],
        };
    },
    mounted() {
        this.initailized = true;
        console.log("path", this.$route.path.match(regex)[2])
    },
    computed: {
        currentEventId() {
            return this.$route.params.eventId;
        },
        displayEventName() {
            if (this.events) {
                const foundEvent = this.events.find((event) => event.code === this.currentEventId);
                return foundEvent ? foundEvent.label : "";
            } else {
                return "";
            }
        },
        stampDone() {
            return this.stampResult ? this.stampResult.status === "done" : false;
        },
        stampError() {
            return this.stampResult ? this.stampResult.status === "error" : false;
        },
        showPhone() {
            if (this.path === routers.register) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
        // 當 isDisplayResult 改變時要進行的操作
        isDisplayResult: function (newVal) {
            if (newVal) {
                this.handleDisplayResult();
            }
        },
    },
    beforeDestroy() {
        // 在組件銷毀前，移除 keydown 事件的監聽器，避免出現記憶體洩漏
        document.removeEventListener("keydown", this.handleKeydownRefocusInput);
    },
    methods: {
        handleDisplayResult() {
            this.changetStampStatus("");

            // 是否要按確認按鈕才能報到成功
            if (!this.hasConfirmBtn) {
                this.checkStamp()
            }
            // 先移除之前的 keydown 事件監聽器
            document.removeEventListener("keydown", (event) => this.handleKeydownRefocusInput(event, this.hasConfirmBtn));
            // 監聽全螢幕，當 keydown 事件發生時，將焦點重新設定回 checkBtnRef
            document.addEventListener("keydown", (event) => this.handleKeydownRefocusInput(event, this.hasConfirmBtn));
        },
        handleKeydownRefocusInput(event, hasConfirmBtn) {
            // 是否要按確認按鈕才能報到成功
            if (hasConfirmBtn) {
                if (this.$refs.checkBtnRef) {
                    // 點擊事件發生時，檢查點擊的目標是否在 checkBtnRef 範圍內，如果不是則將焦點重新設定回 checkBtnRef
                    if (!this.$refs.checkBtnRef.contains(event.target)) {
                        this.$refs.checkBtnRef.focus();
                    }
                    // 如點擊的目標在 checkBtnRef 範圍內，就將焦點重新設定到 finishBtnRef
                    else {
                        this.$refs.finishBtnRef.focus();
                    }
                }
            } else {
                if (this.$refs.finishBtnRef && !this.$refs.finishBtnRef.contains(event.target)) {
                    this.$refs.finishBtnRef.focus();
                }
            }
        },
        callCheckEvent(data) {
            let config = {
                url: `${process.env.VUE_APP_DOMAIN}`,
                method: "POST",
                data: data
            };

            return axios(config);
        },
        changetStampStatus(status) {
            Object.assign(this.stampResult, {
                status: status
            });
        },
        checkStamp() {
            let millisTs = Date.now();
            let seconds = Math.floor(millisTs / 1000);
            let callCheckEventData = {
                eventTs: `${seconds}`,
                // eventType: "stampcard_add",
                // lineUserId: this.member.id,
                // stampId: this.currentEventId
                eventType: "activity_task",
                data: {
                    userId: this.member.id,
                    taskId: this.currentEventId
                }
            };
            this.callCheckEvent(callCheckEventData)
                .then((res) => {
                    console.log("res", res);
                    this.member.phone = res.data.phone
                    this.changetStampStatus("done");
                    this.isScanConfirmed = true
                    setTimeout(() => {
                        this.isScanConfirmed = false
                        this.$emit("scan-finish");
                    }, restartAfterScanFinishMillis);
                })
                .catch((err) => {
                    console.log("err", err);
                    this.changetStampStatus("error");
                    setTimeout(() => {
                        this.isScanConfirmed = false
                        this.$emit("scan-finish");
                    }, restartAfterScanFinishMillis);
                });
        },
        finish() {
            this.isScanConfirmed = false
            this.$emit("scan-finish");
        }
        // resetAll() {
        //     this.member.reset();
        //     this.hideResult();
        //     this.changetStampStatus("");
        //     this.enableCheckStampButton();
        // },
        // chooseEvent() {
        //     this.currentEventId = null;
        //     this.isScan = false;
        //     this.resetAll();
        //     location.reload();
        // },
    },
};
</script>