const device = { barcode: "barcode", camera: "camera" };

export const routers = {
  // 報到拿金幣
  register: "0587fhsly5",
  // 集章任務
  tasks: "3h2e96j7t3",
  // 舞台QA
  stage: "7dedmdnwxr",
  // 縣政宣導
  county1: "98gf7qpl2h",
  county2: "a8qlt0vz5s",
  county3: "domru6r8xu",
  county4: "fhhq63eblj",
  county5: "il89xsczne",
  county6: "l9r8w51qm5",
  county7: "nhu6yzfrwj",
  county8: "rq0y5i1oys",
  county9: "ujfhrlz90l",
  county10: "wqpxws6m5v",
  // TCar綠島
  tCardGreenIsland: "49mupitbof",
  // 松市補金幣
  songshanFair:"elap7b9x8h"
};

export const titles = {
  // 報到拿金幣
  register: "選擇關卡",
  // 集章任務
  tasks: "超級Ｔ粉挑戰",
  // 舞台QA
  stage: "線上小學堂",
  // 縣政宣導
  county1: "縣政宣導 報到領金幣",
  county2: "縣政宣導 報到領金幣",
  county3: "縣政宣導 報到領金幣",
  county4: "縣政宣導 報到領金幣",
  county5: "縣政宣導 報到領金幣",
  county6: "縣政宣導 報到領金幣",
  county7: "縣政宣導 報到領金幣",
  county8: "縣政宣導 報到領金幣",
  county9: "縣政宣導 報到領金幣",
  county10: "縣政宣導 報到領金幣",
  // TCar綠島
  tCardGreenIsland: "選擇關卡",
  // 松市補金幣
  songshanFair:"松市補金幣"
};

export const registerEvent = [
  {
    code: "1",
    label: "9/2舖食節：報到拿金幣",
    description: "9/2舖食節：報到拿金幣",
    device: [device.barcode, device.camera],
  },
];

export const tasksEvents = [
  {
    code: "2",
    label: "自備環保容器",
    description: "自備環保容器",
    device: [device.camera],
  },
  {
    code: "3",
    label: "慢活場域消費集章",
    description: "慢活場域消費集章",
    device: [device.camera],
  },
  {
    code: "4",
    label: "漫遊場域消費集章",
    description: "漫遊場域消費集章",
    device: [device.camera],
  },
  {
    code: "5",
    label: "慢時場域消費集章",
    description: "慢時場域消費集章",
    device: [device.camera],
  },
  {
    code: "6",
    label: "縣政宣導攤完成任務1",
    description: "縣政宣導攤完成任務1",
    device: [device.camera],
  },
  {
    code: "7",
    label: "縣政宣導攤完成任務2",
    description: "縣政宣導攤完成任務2",
    device: [device.camera],
  },
  {
    code: "8",
    label: "參加現場食農小學堂",
    description: "參加現場食農小學堂",
    device: [device.camera],
  },
  {
    code: "9",
    label: "TTFREE連線",
    description: "TTFREE連線",
    device: [device.camera],
  },
  {
    code: "10",
    label: "完成衛生局指定任務",
    description: "完成衛生局指定任務",
    device: [device.camera],
  },
  {
    code: "11",
    label: "綁定NFT",
    description: "綁定NFT",
    device: [device.camera],
  },
];

export const stageEvents = [
  {
    code: "21",
    label: "各場次第一名",
    description: "各場次第一名",
    device: [device.camera],
  },
  {
    code: "22",
    label: "各場次第二名",
    description: "各場次第二名",
    device: [device.camera],
  },
  {
    code: "23",
    label: "各場次第三名",
    description: "各場次第三名",
    device: [device.camera],
  },
];

export const county1Event = [
  {
    code: "101",
    label: "建設處",
    description: "建設處",
    device: [device.camera],
  },
];

export const county2Event = [
  {
    code: "102",
    label: "警察局",
    description: "警察局",
    device: [device.camera],
  },
];

export const county3Event = [
  {
    code: "103",
    label: "財政經濟發展處菸酒管理科",
    description: "財政經濟發展處菸酒管理科",
    device: [device.camera],
  },
];

export const county4Event = [
  {
    code: "104",
    label: "稅務局",
    description: "稅務局",
    device: [device.camera],
  },
];

export const county5Event = [
  {
    code: "105",
    label: "政風處",
    description: "政風處",
    device: [device.camera],
  },
];

export const county6Event = [
  {
    code: "106",
    label: "陸軍指揮部招募組",
    description: "陸軍指揮部招募組",
    device: [device.camera],
  },
];

export const county7Event = [
  {
    code: "107",
    label: "農業部林業及自然保育署臺東分署",
    description: "農業部林業及自然保育署臺東分署",
    device: [device.camera],
  },
];

export const county8Event = [
  {
    code: "108",
    label: "地政處",
    description: "地政處",
    device: [device.camera],
  },
];

export const county9Event = [
  {
    code: "109",
    label: "家庭教育中心",
    description: "家庭教育中心",
    device: [device.camera],
  },
];

export const county10Event = [
  {
    code: "110",
    label: "社會處",
    description: "社會處",
    device: [device.camera],
  },
];

export const tCardGreenIslandEvent = [
  {
    code: "1440",
    label: "9/16 TCar綠島",
    description: "9/16 TCar綠島",
    device: [device.barcode, device.camera],
  },
];

export const songshanFairEvent = [
  {
    code: "1464",
    label: "松市補金幣",
    description: "松市補金幣",
    device: [device.barcode, device.camera],
  },
];
export const hasConfirmBtn = { barcodeScan: false, cameraScan: true };

// 集合
export const activitiesInfo = {
  register: {
    route: routers.register,
    title: titles.register,
    event: registerEvent
  },
  tasks: {
    route: routers.tasks,
    title: titles.tasks,
    event: tasksEvents
  },
  stage: {
    route: routers.stage,
    title: titles.stage,
    event: stageEvents
  },
  county1: {
    route: routers.county1,
    title: titles.county1,
    event: county1Event
  },
  county2: {
    route: routers.county2,
    title: titles.county2,
    event: county2Event
  },
  county3: {
    route: routers.county3,
    title: titles.county3,
    event: county3Event
  },
  county4: {
    route: routers.county4,
    title: titles.county4,
    event: county4Event
  },
  county5: {
    route: routers.county5,
    title: titles.county5,
    event: county5Event
  },
  county6: {
    route: routers.county6,
    title: titles.county6,
    event: county6Event
  },
  county7: {
    route: routers.county7,
    title: titles.county7,
    event: county7Event
  },
  county8: {
    route: routers.county8,
    title: titles.county8,
    event: county8Event
  },
  county9: {
    route: routers.county9,
    title: titles.county9,
    event: county9Event
  },
  county10: {
    route: routers.county10,
    title: titles.county10,
    event: county10Event
  },
  tCardGreenIsland: {
    route: routers.tCardGreenIsland,
    title: titles.tCardGreenIsland,
    event: tCardGreenIslandEvent
  },
  songshanFair: {
    route: routers.songshanFair,
    title: titles.songshanFair,
    event: songshanFairEvent
  }
};
